<template>
	<div
		class="tab-bar"
		role="tablist"
		ref="tab"
		:class="[small && 'small']"
	>
		<div
			class="tab-set"
			:class="{ loading: loading }"
		>
			<div
				v-for="(tab, index) in tabs"
				class="tab current-add"
				:class="{ active: selected === index, inactive: selected !== index }"
				role="presentation"
			>
				<div class="text-wrapper">
					<Component
						:is="link ? 'a' : 'span'"
						href="javascript:"
						class="link"
						:class="{ large: fontSize === 'large' }"
						:aria-selected="selected === index"
						@click.stop.prevent="selectTab(index)"
						@keyup.enter="selectTab(index)"
					>
						{{ tab }}
					</Component>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default defineNuxtComponent({
	name: "Tabs",

	props: {
		tabs: { type: Array, default: [] },
		selected: { type: Number, default: 0 },
		fontSize: { type: String, default: "normal" }, // if larger font wanted, pass in 'large'. },
		small: { type: Boolean, default: false },
		link: { type: Boolean, default: true },
		name: { type: String, default: "tab" },
		leaveUrlAlone: { type: Boolean, default: false }, // Avoid this component to hijack the url.
		loading: { type: Boolean, default: false },
	},

	computed: {
		hashFragments() {
			if (import.meta.client && !this.leaveUrlAlone) {
				if (location.hash.length === 0) {
					return {};
				}

				let hashFragments = location.hash.replace("#", "").split("&");

				hashFragments = hashFragments.reduce((prev, item) => {
					const key = item.split("=")[0];
					const value = item.split("=")[1];

					return Object.assign({ [key]: value }, prev);
				}, {});

				return hashFragments;
			} else {
				return {};
			}
		},
	},

	methods: {
		/**
		 * Tabs emits selected index for selected tab. This must be handled in parent component using:
		 * v-on:TabSelection="<function>"
		 */
		selectTab(index) {
			this.$emit("TabSelection", { index });
			if (index !== this.selected && !this.leaveUrlAlone) {
				const newUrl =
					window.location.pathname +
					"#" +
					this.stringifyHashFragments({
						...this.hashFragments,
						[this.name]: index,
					});
				history.replaceState(null, "", newUrl);
			}
		},
		stringifyHashFragments(hashFragments) {
			return Object.keys(hashFragments)
				.map((key) => key + "=" + hashFragments[key])
				.join("&");
		},
	},

	beforeMount() {
		if (this.hashFragments[this.name] && !this.leaveUrlAlone) {
			this.selectTab(parseInt(this.hashFragments[this.name]));
			// build
		}
	},
});
</script>

<style lang="scss" scoped>
.tab-bar {
	margin: 0 auto;

	&.grey {
		background: $color-background-grey;
	}

	.tab-set {
		font-size: 0;
		display: flex;
		overflow: auto;

		@include loading;

		&.loading {
			margin: 0 auto;
			max-width: 250px;
		}

		.tab {
			display: inline-block;
			position: relative;
			cursor: pointer;
			flex-shrink: 0;

			@include font-text-m;

			&::after {
				content: "";
				display: block;
				position: absolute;
				width: 0;
				height: 1px;
				bottom: 0;
				background: $color-information-500-core;
				transition: width 0.25s;
			}

			&.active::after {
				width: 100%;
			}

			&.inactive::after {
				width: 0;
				right: 0;
			}

			.link {
				display: block;
				padding: 20px;
				color: $color-text;
				text-decoration: none;
				border-bottom: 0;

				&.large {
					@include font-text-l;
				}
			}
		}

		@media screen and (max-width: $size-screen-desktop) {
			text-align: center;
			justify-content: flex-start;
		}
	}

	&.small {
		.tab {
			.link {
				padding: 10px;

				@include font-text-s;
			}

			&::after {
				background: $color-information-500-core;
				height: 2px;
			}
		}
	}
}
</style>
